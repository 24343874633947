const axios = require('axios');

const { REACT_APP_API_URL, REACT_APP_STRAPI_TOKEN_KEY } = process.env;

export function processRefund(data){
    return axios.post(`${REACT_APP_API_URL}/checkouts/processrefund`, data, {
        // headers: {
        //     Authorization: `Bearer ${REACT_APP_STRAPI_TOKEN_KEY}`
        // }
    });
}
